jQuery.noConflict();
/* Site wide JS */
jQuery(document).ready(function( $ ) {
    $(function(){
        var body = $("body");
        $(window).on('load scroll resize orientationchange', function () {
            if ($(window).scrollTop() >= 65) { 
                body.removeClass('head-top').addClass("head-scroll");
            }
            else{
                body.removeClass("head-scroll").addClass('head-top');
            }
        });
    });
    $(".product").click(function() {
	  window.location = $(this).find("a").attr("href"); 
	  return false;
	});
	$(".product-tile").click(function() {
	  window.location = $(this).find("a").attr("href"); 
	  return false;
	});
	$(".article").click(function() {
	  window.location = $(this).find("a").attr("href"); 
	  return false;
	});
    $(".case-tile").click(function() {
      window.location = $(this).find("a").attr("href"); 
      return false;
    });

    $("img.aligncenter").parents('p').css("text-align", "center");
	$(function() {
	    var $allVideos = $("iframe[src^='//player.vimeo.com'], iframe[src^='//www.youtube.com'], object, embed"),
	    $fluidEl = $("figure");
		$allVideos.each(function() {
		  $(this)
		    // jQuery .data does not work on object/embed elements
		    .attr('data-aspectRatio', this.height / this.width)
		    .removeAttr('height')
		    .removeAttr('width');
		});
		$(window).resize(function() {
		  var newWidth = $fluidEl.width();
		  $allVideos.each(function() {
		    var $el = $(this);
		    $el
		        .width(newWidth)
		        .height(newWidth * $el.attr('data-aspectRatio'));

		  });
		}).resize();
	});
	$(".toggle-demo").click(function(event) {
		$("#book-a-demo").toggleClass( "show" );
	});
	$('.dotMailer_news_letter #dotMailer_email').focus(function() {
        $("label[for='dotMailer_email']").hide();
    })
    $('.dotMailer_news_letter #dotMailer_email').blur(function() {
    	if( !this.value ) {
    		$("label[for='dotMailer_email']").hide();
    	}
    });
	// Show the first tab and hide the rest
	$('#tabs-nav li:first-child').addClass('active');
	$('.tab-content').hide();
	$('.tab-content:first').show();

	// Click function
	$('#tabs-nav li').click(function(){
	  $('#tabs-nav li').removeClass('active');
	  $(this).addClass('active');
	  $('.tab-content').hide();
	  var activeTab = $(this).find('a').attr('href');
	  $(activeTab).fadeIn();
	  return false;
	});
	if(jQuery(window).width() <= 768){
		$('.search-box').appendTo('#main-header .container');
		$('.toggle-search').click(function() {
		  $('#search-form').removeClass('search-bar').addClass("active");
		});
		$('.search-close').click(function() {
		  $('#search-form').removeClass('active').addClass("search-bar");
		});
	}
	$(window).resize(function(){
		if(jQuery(window).width() <= 768){
			$('.search-box').appendTo('#main-header .container');
			$('.toggle-search').click(function() {
			  $('#search-form').removeClass('search-bar').addClass("active");
			});
			$('.search-close').click(function() {
			  $('#search-form').removeClass('active').addClass("search-bar");
			});
		} else {
			$('.search-box').appendTo('#top-nav .container');
		}
	})
	if(jQuery(window).width() <= 992){
        $('#top-products').slick({
            dots: false,
			infinite: true,
			speed: 300,
			slidesToShow: 4,
			slidesToScroll: 4,
			responsive: [
				{
				breakpoint: 1024,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3,
					}
				},
					{
					breakpoint: 600,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 2
						}
					},
				{
				breakpoint: 480,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
				// You can unslick at a given breakpoint now by adding:
		    // settings: "unslick"
		    // instead of a settings object
		  ]
        });
    }
    $(window).resize(function(){
        var top_products ='';
        if(jQuery(window).width() <= 992){
            top_products = $('#top-products').slick({
                dots: false,
				infinite: true,
				speed: 300,
				slidesToShow: 4,
				slidesToScroll: 4,
				responsive: [
					{
					breakpoint: 1024,
						settings: {
							slidesToShow: 3,
							slidesToScroll: 3,
						}
					},
						{
						breakpoint: 600,
							settings: {
								slidesToShow: 2,
								slidesToScroll: 2
							}
						},
					{
					breakpoint: 480,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1
						}
					}
					// You can unslick at a given breakpoint now by adding:
			    // settings: "unslick"
			    // instead of a settings object
			  ]
            });
        } else {
            $("#top-products").slick('unslick');
        }   
    })
	$('.quick-products').slick({
		dots: false,
		infinite: true,
		speed: 300,
		slidesToShow: 4,
		slidesToScroll: 4,
		responsive: [
			{
			breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
				}
			},
				{
				breakpoint: 600,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2
					}
				},
			{
			breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
			// You can unslick at a given breakpoint now by adding:
	    // settings: "unslick"
	    // instead of a settings object
	  ]
    });
    $('#home-slider').slick({
		dots: true,
		infinite: true,
		speed: 300,
		slidesToShow: 1,
		slidesToScroll:1,
    });
    $(window).scroll(function() {
		$('video').each(function(){
			if ($(this).is(":in-viewport( 400 )")) {
				$(this)[0].play();
			} else {
				$(this)[0].pause();
			}
		});
	});
	// onClick new options list of new select
	$('.ae-select-content').text($('.dropdown-menu > li.active').text());
		var newOptions = $('.dropdown-menu > li');
		newOptions.click(function() {
		$('.ae-select-content').text($(this).text());
		$('.dropdown-menu > li').removeClass('active');
		$(this).addClass('active');
	});

	var aeDropdown = $('.ae-dropdown');
	aeDropdown.click(function() {
		$('.dropdown-menu').toggleClass('ae-hide');
	});
	$('#book-a-demo').click(function(event) {
		event.preventDefault();
		$('.modal').css({
			'visibility': 'visible',
			'opacity': '1',
		});
		$('.overlay').css({
			'visibility': 'visible',
			'opacity': '1',
		});
	});

	$(document).mouseup(function(e) {

	var container = $(".modal");

	if (!container.is(e.target) // if the target of the click isn't the container...
	&& container.has(e.target).length === 0) // ... nor a descendant of the container
		{
			$('.modal').css({
				'visibility': 'hidden',
				'opacity': '0',
			});
			$('.overlay').css({
				'visibility': 'hidden',
				'opacity': '0',
			});
		}
	});
});
jQuery(window).on('load', function() {
	jQuery('body').addClass('loaded');
});
jQuery(document).ready(function() {
    var accordionParentClass = '.faq';
    var accordionQuestionClass = '.faq .question';
    var accordionContentClass = '.answer';
    jQuery(accordionParentClass).each(function() {
		jQuery(this).addClass('close');
    });
    jQuery(accordionQuestionClass).click(function(){
		var faqClass = jQuery(this).closest(accordionParentClass).attr('class');
		console.log(faqClass);
		if(faqClass.indexOf('close') != -1){
			//WHEN CLOSED
			jQuery(accordionParentClass).addClass('close').removeClass('open'); //set all faq as closed

			jQuery(this).closest(accordionParentClass).removeClass('close');
			jQuery(this).closest(accordionParentClass).addClass('open');

		} else {
			jQuery(this).closest(accordionParentClass).addClass('close');
			jQuery(this).closest(accordionParentClass).removeClass('open');
		}
    });
});
AOS.init({
   once: true
})